import { Pipe, PipeTransform } from '@angular/core';
import { canShiftTimesAndLocationsBeReported } from './can-shift-times-and-locations-be-reported.fn';
import {
  ResolvedShiftWithReports,
  ShiftWithActivitiesWithLocations,
} from '@wilson/interfaces';

@Pipe({
  name: 'canShiftTimesAndLocationsBeReported',
  standalone: true,
})
export class CanShiftTimesAndLocationsBeReportedPipe implements PipeTransform {
  transform(
    shift: ResolvedShiftWithReports | ShiftWithActivitiesWithLocations,
  ): boolean {
    return canShiftTimesAndLocationsBeReported(shift);
  }
}
