import { Pipe, PipeTransform } from '@angular/core';
import { FeaturePurePipe } from '@wilson/authorization';
import {
  FeatureName,
  ResolvedShiftWithReports,
  ShiftWithActivitiesWithLocations,
} from '@wilson/interfaces';
import { Observable, of, switchMap } from 'rxjs';
import { canShiftTimesAndLocationsBeReported } from './can-shift-times-and-locations-be-reported.fn';

@Pipe({
  name: 'canContinueToShiftSubmission',
  standalone: true,
})
export class CanContinueToShiftSubmissionPipe implements PipeTransform {
  constructor(private readonly featurePurePipe: FeaturePurePipe) {}

  transform(
    shift: ResolvedShiftWithReports | ShiftWithActivitiesWithLocations,
  ): Observable<boolean> {
    return of(shift).pipe(
      switchMap((shift) => {
        if (!shift || !canShiftTimesAndLocationsBeReported(shift)) {
          return of(false);
        }
        return this.featurePurePipe.transform(FeatureName.MobileShiftReporting);
      }),
    );
  }
}
