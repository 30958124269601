import {
  ActivityReportCategory,
  ActivityReportType,
  GeoLocation,
  ResolvedActivityReport,
  ResolvedActivityWithRequiredActivityReportFields,
} from '@wilson/interfaces';
import { activityReportFactory } from './activity-report.factory';

/**
 * Creates a modified activity report based on the provided activity, existing report, and modifications.
 *
 * This factory function generates a new `ResolvedActivityReport` by either using values from an existing report
 * (if provided) or falling back to the corresponding fields in the `ResolvedActivityWithRequiredActivityReportFields`.
 * It also applies optional modifications to `dateTime`, `location`, and `locationId` fields if specified.
 *
 * Modifications can be provided as direct values or functions that receive the base value and return a new one.
 *
 * @param {ResolvedActivityWithRequiredActivityReportFields} activity - The activity containing the base data for the report.
 * @param {ResolvedActivityReport | null} existingReport - The existing report, if available, to use as the base for modifications.
 * @param {ActivityReportCategory} reportCategory - The category of the activity report (e.g., `Start` or `End`).
 * @param {{
 *   dateTime?: string | ((value: string) => string);
 *   location?: GeoLocation | ((value: GeoLocation) => GeoLocation);
 *   locationId?: string | ((value: string) => string);
 * }} modifications - An object containing optional modifications for specific fields:
 *   - `dateTime` (optional): A direct value or function to modify the base `dateTime`.
 *   - `location` (optional): A direct value or function to modify the base `location`.
 *   - `locationId` (optional): A direct value or function to modify the base `locationId`.
 * @returns {ResolvedActivityReport} - A new activity report with the applied modifications and fallback logic.
 *
 * @example
 * // Using direct values
 * const newReport = modifiedActivityReportFactory(
 *   activity,
 *   existingReport,
 *   ActivityReportCategory.Start,
 *   {
 *     location: newGeoLocation,
 *   }
 * );
 *
 * // Using modification functions
 * const newReport = modifiedActivityReportFactory(
 *   activity,
 *   existingReport,
 *   ActivityReportCategory.Start,
 *   {
 *     dateTime: (value) => addMinutes(new Date(value), 15).toISOString(),
 *   }
 * );
 */
export const modifiedActivityReportFactory = (
  activity: ResolvedActivityWithRequiredActivityReportFields,
  existingReport: ResolvedActivityReport | null,
  reportCategory: ActivityReportCategory,
  modifications: {
    dateTime?: string | ((value: string) => string);
    location?: GeoLocation | ((value: GeoLocation) => GeoLocation);
    locationId?: string | ((value: string) => string);
  },
): ResolvedActivityReport => {
  const baseId = existingReport?.id || activity.id;
  const baseDateTime =
    existingReport?.dateTime ||
    (reportCategory === ActivityReportCategory.Start
      ? activity.startDatetime
      : activity.endDatetime);

  const baseLocation =
    existingReport?.location ||
    (reportCategory === ActivityReportCategory.Start
      ? activity.startLocation
      : activity.endLocation);

  const baseLocationId =
    existingReport?.locationId ||
    (reportCategory === ActivityReportCategory.Start
      ? activity.startLocationId
      : activity.endLocationId);

  const dateTime =
    modifications.dateTime !== undefined
      ? typeof modifications.dateTime === 'function'
        ? modifications.dateTime(baseDateTime)
        : modifications.dateTime
      : baseDateTime;

  const location =
    modifications.location !== undefined
      ? typeof modifications.location === 'function'
        ? modifications.location(baseLocation)
        : modifications.location
      : baseLocation;

  const locationId =
    modifications.locationId !== undefined
      ? typeof modifications.locationId === 'function'
        ? modifications.locationId(baseLocationId)
        : modifications.locationId
      : baseLocationId;

  return activityReportFactory(
    baseId,
    dateTime,
    location,
    locationId,
    reportCategory,
    ActivityReportType.IsTimes,
  );
};
