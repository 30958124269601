import { Pipe, PipeTransform } from '@angular/core';
import {
  Activity,
  ActivityReport,
  FullActivityReport,
  Shift,
} from '@wilson/interfaces';
import { ReportUtilityService } from '../services/report-utility.service';

@Pipe({
  name: 'shiftHasReports',
})
export class ShiftHasReportsPipe implements PipeTransform {
  constructor(private reportUtility: ReportUtilityService) {}

  transform(
    shift: Shift & {
      activities: (Activity & { activityReports: ActivityReport[] })[];
    },
  ): boolean {
    const shiftReport = this.reportUtility.getShiftReport(shift);
    return (
      !!shiftReport && !!shiftReport.startDatetime && !!shiftReport.endDatetime
    );
  }
}
