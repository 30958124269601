import {
  ResolvedShiftWithReports,
  Shift,
  ShiftWithActivitiesWithLocations,
} from '@wilson/interfaces';
import { areAllActivitiesFinished } from './are-all-activities-finished.fn';
import { isOneActivityStarted } from './is-one-activity-started.fn';
import { isShiftInModifiableShiftState } from './is-shift-in-modifiable-shift-state.fn';
import { isShiftInPast } from './is-shift-in-past.fn';

export function canShiftTimesAndLocationsBeReported(
  shift: ResolvedShiftWithReports | ShiftWithActivitiesWithLocations,
): boolean {
  return (
    isShiftInModifiableShiftState(shift) &&
    (areAllActivitiesFinished(shift.activities) ||
      (!isOneActivityStarted(shift.activities) &&
        isShiftInPast(shift.activities)))
  );
}
