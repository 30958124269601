import { Shift, ShiftState } from '@wilson/interfaces';

export function isShiftInModifiableShiftState(
  shift: Pick<Shift, 'state'>,
): boolean {
  return (
    shift.state === ShiftState.NotSubmitted ||
    shift.state === ShiftState.Reopened
  );
}
