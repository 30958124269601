import { Pipe, PipeTransform } from '@angular/core';
import { Shift } from '@wilson/interfaces';
import { isShiftInModifiableShiftState } from './is-shift-in-modifiable-shift-state.fn';

@Pipe({
  name: 'isShiftInModifiableShiftState',
  standalone: true,
})
export class IsShiftInModifiableShiftStatePipe implements PipeTransform {
  transform(shift: Pick<Shift, 'state'>): boolean {
    return isShiftInModifiableShiftState(shift);
  }
}
